import { computed } from 'vue';
import { useStore } from 'vuex';

// types
import type { ComputedRef } from 'vue';
import type { ProductItem } from '@/types/product';
import { WopsOrderInterface } from '@/classes/order';
import outgoingOrder from '@/store/modules/outgoingOrder';
import { WopsGiftInterface } from '../classes/gift';

interface UseOrderLines {
  products: ComputedRef<ProductItem[]>;
  productCount: ComputedRef<number>;
  item: ComputedRef<WopsOrderInterface | WopsGiftInterface>;
  productsByBrand: ComputedRef<ProductItem[]>;
}

export default function (): UseOrderLines {
  const store = useStore();
  const item: ComputedRef<WopsOrderInterface> = computed(() => store.state.outgoingOrder.item);
  const orderItems: ComputedRef<ProductItem[]> = computed(() => store.state.outgoingOrder.orderItems);
  const products: ComputedRef<ProductItem[]> = computed(() => orderItems.value);
  const productsByBrand: ComputedRef<ProductItem[]> = computed(
    () => store.getters['outgoingOrder/getOutgoingOrderItemsBySortedBrand']
  );
  const productCount: ComputedRef<number> = computed(() => orderItems.value?.length);
  return {
    products,
    productCount,
    item,
    productsByBrand,
  };
}
