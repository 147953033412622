import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

import { computed } from 'vue';
import { useStore } from 'vuex';

// Components - Import
import { BaseContainer, BaseColumn, BaseGrid } from '@/components/primitives';
import { BaseText, BaseButton, Notification } from '@/components/partials';
import { VerticalTable } from '@/components/containers';
import { BaseForm } from '@/components/partials/forms';

// Constants - Import
import ShippingAddonNames from '@/constants/shippingAddons/shippingAddonNames';
import OrderProcessTypes from '@/constants/orderPriority/orderProcessTypes';

// Types - Import
import type { ComputedRef } from 'vue';
import type { WopsOrderInterface } from '@/classes/order';
import type { TableRow } from '@/types/table';
import type { ProductItem } from '@/types/product';

// @use - Import
import useOrder from '@/@use/order';
import useOrderLines from '@/@use/orderLines';

// @use - Envoke

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

/*------------------------------
- Vue
-- Import
- Components
-- Import
- Constants
-- Import
- Types
-- Import
- @use
-- Import
-- Envoke
- Start pack
- Table data
-------------------------------*/

// Vue - Import
const store = useStore();
const { item, deliveryMethod, isNewCustomer, isGiftOrder, giftCode, highLightValue } = useOrder();
const { productCount } = useOrderLines();

// Start pack
const hasPageErrors: ComputedRef<boolean> = computed(() => store.getters['page/getHasErrors']);
const loading: ComputedRef<boolean> = computed(() => store.getters['outgoingOrder/getStoreLoading']);

const ctaMessage: ComputedRef<string> = computed(() => {
  return item.value?.assigneeId === store.getters['auth/getId'] ? 'Start pack' : 'Continue pack';
});

const onStartPack = async (): Promise<void> =>
  await store.dispatch('outgoingOrder/assignItem', OrderProcessTypes.PACKING);

// Table
store.dispatch('outgoingOrder/getOutgoingItemDetails', OrderProcessTypes.PACKING);
const tableRows: ComputedRef<TableRow[]> = computed(() => {
  return [
    {
      title: {
        content: 'Dispatch By',
      },
      columns: [
        {
          content: item?.value?.dispatchBy ?? '',
          color: item?.value?.isPastDueDispatch ? 'danger' : 'default',
        },
      ],
    },
    {
      title: {
        content: 'Reuasable Packaging',
      },
      columns: [
        {
          content: item?.value?.hasReusablePackagingOptIn,
        },
      ],
    },
    {
      title: {
        content: 'Delivery',
      },
      columns: [
        {
          content: deliveryMethod.value,
          color: deliveryMethod.value === ShippingAddonNames.EXPRESS_DELIVERY ? 'danger' : 'default',
        },
      ],
    },
    {
      title: {
        content: 'New customer',
      },
      columns: [
        {
          content: isNewCustomer.value,
          color: isNewCustomer.value === 'Yes' ? 'danger' : 'default',
        },
      ],
    },
    {
      title: {
        content: 'Products',
      },
      columns: [{ content: productCount.value }],
    },
    {
      title: {
        content: 'Is gift',
      },
      columns: [
        {
          content: isGiftOrder.value ? 'Yes' : 'No',
          color: isGiftOrder.value ? 'warning' : 'default',
        },
      ],
    },
    {
      title: {
        content: 'Extra Careful',
      },
      columns: [
        {
          content: highLightValue.value,
          color: 'default',
        },
      ],
    },
    {
      title: {
        content: 'Gift code',
      },
      columns: [
        {
          content: giftCode.value,
          color: 'default',
        },
      ],
    },
    {
      title: {
        content: 'Bin location',
      },
      columns: [{ content: item.value?.binLocation }],
    },
  ];
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseContainer), null, {
    default: _withCtx(() => [
      _createVNode(_unref(BaseGrid), null, {
        default: _withCtx(() => [
          (!hasPageErrors.value)
            ? (_openBlock(), _createBlock(_unref(BaseColumn), {
                key: 0,
                span: { tablet: '6' }
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(VerticalTable), {
                    rows: tableRows.value,
                    "is-loaded": !loading.value
                  }, null, 8, ["rows", "is-loaded"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_unref(BaseColumn), {
            span: { tablet: '1' },
            class: "mobile-hidden"
          }),
          _createVNode(_unref(BaseColumn), {
            span: { tablet: '5' },
            justify: { tablet: 'end' }
          }, {
            default: _withCtx(() => [
              (!hasPageErrors.value)
                ? (_openBlock(), _createBlock(_unref(BaseButton), {
                    key: 0,
                    onClick: onStartPack,
                    color: "success",
                    "is-loading": loading.value
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(ctaMessage.value), 1)
                    ]),
                    _: 1
                  }, 8, ["is-loading"]))
                : (_openBlock(), _createBlock(_unref(BaseButton), {
                    key: 1,
                    url: "/dashboard",
                    color: "danger"
                  }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode("Return to Dashboard")
                    ])),
                    _: 1
                  }))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})